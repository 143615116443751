import React from 'react';
import { useSelector } from 'react-redux';

import Collapsible from '../../../../components/Collapsible';
import Utils from '../../../../helpers/utils';

export default function ShippingTable() {
  const { detailResult: order } = useSelector((state) => state.order);

  return (
    <Collapsible title="Informasi Pengiriman">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="font-bold text-lg">Shipping Address</h2>
          <div className="mt-1">
            <p>{order.street_address}</p>
            <p>{`${order.district}, ${order.city}`}</p>
            <p>{`${order.province} ${order.zip_code}`}</p>
          </div>
          <div>{`${order.first_name} ${order.last_name} (Ph: ${order.phone})`}</div>
        </div>

        <div>
          <h2 className="font-bold text-lg">Shipping Method</h2>
          <div className="mt-1">
            <div className="-ml-2 px-2 py-1 bg-gray-100 rounded">
              <span className="uppercase">{order.shipping_type} : </span>
              <span>{order.shipping_type === 'Self Pickup' ? order.warehouse?.name : ''} </span>
              <span>
                {order.shipping_method_name} {order.shipping_method_detail}
              </span>
            </div>
            <div className="mt-1">
              <p>{`Shipping Fee: Rp ${Utils.numberFormat(order.total_shipping_fee, 0)}`}</p>
              <p>{`Berat: ${Utils.numberFormat(order.total_weight, 0)} gram`}</p>
              <p className="font-bold">{`No Resi: ${order.resi_no ? order.resi_no : '-'}`}</p>
            </div>
          </div>
        </div>
      </div>
    </Collapsible>
  );
}
