import React, { useState, useEffect } from 'react';
import { TruckIcon, CubeIcon } from '@heroicons/react/outline';
import { useSelector, useDispatch } from 'react-redux';

import Collapsible from '../../components/Collapsible';
import { InlineSelect, Spinner } from '../../components/Base';
import Utils from '../../helpers/utils';
import { SHIPPING_TYPES } from '../../constants/data';

import {
  setShippingType,
  setShippingWarehouse,
  setShippingMethod,
  setShippingMethodType,
} from '../../actions/checkout';
import { fetchShippingFee, resetShippingFee } from '../../actions/rajaongkir';

export default function ShippingForm() {
  const { result: carts } = useSelector((state) => state.cart);
  const { result: warehouses } = useSelector((state) => state.warehouse);
  const { result: shippingMethods } = useSelector((state) => state.shippingMethod);
  const checkout = useSelector((state) => state.checkout);
  const { shippingFees } = useSelector((state) => state.rajaongkir);
  const dispatch = useDispatch();

  const {
    shipping_type: shippingType = SHIPPING_TYPES.DELIVERY,
    warehouse: selectedWarehouse = null,
  } = checkout;

  const [pickupWarehouseOptions, setPickupWarehouseOptions] = useState([]);
  const [isFetchingShippingFee, setIsFetchingShippingFee] = useState(false);

  //

  // Set Pickup Warehouse according to availability Stock
  useEffect(() => {
    const unqualifiedWarehouseIds = [];
    carts.forEach((cart) => {
      cart.offlineStocks.forEach((stock) => {
        if (stock.stock < cart.quantity) unqualifiedWarehouseIds.push(stock.warehouse_id);
      });
    });
    const pickupWarehouses = warehouses.filter((w) => unqualifiedWarehouseIds.indexOf(w.id) < 0);
    setPickupWarehouseOptions(pickupWarehouses);
  }, [carts, warehouses]);

  // Set Default Warehouse each time Shipping Type was changed
  useEffect(() => {
    if (shippingType === SHIPPING_TYPES.DELIVERY) {
      const warehouse = warehouses.find((w) => Number(w.is_default) === 1);
      dispatch(setShippingWarehouse(warehouse ? warehouse : warehouses[0]));
    } else if (shippingType === SHIPPING_TYPES.PICKUP) {
      dispatch(setShippingWarehouse(warehouses[0]));
    }

    dispatch(resetShippingFee());
  }, [dispatch, warehouses, pickupWarehouseOptions, shippingType]);

  //

  useEffect(() => {
    if (shippingFees.length > 0) {
      const costs = shippingFees[0].costs.filter((c) => c.cost.length > 0);
      if (costs.length > 0) onChangeShippingMethodType(costs[0]);
    }
  }, [shippingFees]);

  //

  const onChangeShippingType = (value) => {
    dispatch(setShippingType(value));
  };

  const onChangeWarehouse = (warehouseId) => {
    const warehouse = warehouses.find((w) => w.id === Number(warehouseId));
    dispatch(setShippingWarehouse(warehouse));
  };

  const onChangeShippingMethod = (shippingMethodId) => {
    const shippingMethod = shippingMethods.find((s) => s.id === Number(shippingMethodId));
    if (shippingMethod) {
      // Fetch Shipping Fee for RajaOngkir
      if (Number(shippingMethod.is_rajaongkir) === 1) {
        setIsFetchingShippingFee(true);
        dispatch(
          fetchShippingFee(
            selectedWarehouse.city_id,
            checkout.city_id,
            checkout.total_weight,
            shippingMethod.rajaongkir_courier_name,
          ),
        ).then(() => setIsFetchingShippingFee(false));
      } else {
        dispatch(resetShippingFee());
      }

      // Set Shipping Method Data to State
      const shippingData = {
        shipping_method_id: shippingMethod.id,
        shipping_method_name: shippingMethod.name,
        additional_shipping_fee: parseFloat(shippingMethod.additional_fee),
        insurance_fee_percentage: parseFloat(shippingMethod.insurance_fee_percentage),
        insurance_fee_amount: parseFloat(shippingMethod.insurance_fee_amount),
      };
      dispatch(setShippingMethod(shippingData));
    }
  };

  const onChangeShippingMethodType = (cost) => {
    const shippingData = {
      shipping_method_detail: cost.service,
      shipping_fee: parseFloat(cost.cost[0].value),
    };
    dispatch(setShippingMethodType(shippingData));
  };

  //

  if (checkout.isEditingUserInformation) return null;
  return (
    <Collapsible title="Metode Pengiriman">
      {/* SHIPPING TYPE SELECTION */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div
          className={`p-3 flex items-center rounded-lg cursor-pointer border-2
              ${shippingType === SHIPPING_TYPES.DELIVERY ? 'border-purple-800' : ''}`}
          onClick={() => onChangeShippingType(SHIPPING_TYPES.DELIVERY)}>
          <TruckIcon
            className={`ml-3 h-10 w-10 opacity-50 ${
              shippingType === SHIPPING_TYPES.DELIVERY ? 'text-purple-800' : ''
            }`}
          />
          <div className="flex-1 pl-6">
            <h4 className="font-bold">{SHIPPING_TYPES.DELIVERY}</h4>
            <div className="text-sm">
              BSTORE akan mengirimkan pesanan Anda sesuai dengan alamat pengiriman
            </div>
          </div>
        </div>
        <div
          className={`p-3 flex items-center rounded-lg cursor-pointer border-2
              ${shippingType === SHIPPING_TYPES.PICKUP ? 'border-purple-800' : ''}`}
          onClick={() => onChangeShippingType(SHIPPING_TYPES.PICKUP)}>
          <CubeIcon
            className={`ml-3 h-10 w-10 opacity-50 ${
              shippingType === SHIPPING_TYPES.PICKUP ? 'text-purple-800' : ''
            }`}
          />
          <div className="flex-1 pl-6">
            <h4 className="font-bold">{SHIPPING_TYPES.PICKUP}</h4>
            <div className="text-sm">
              Ambil pesanan Anda di toko BSTORE terdekat yang Anda inginkan
            </div>
          </div>
        </div>
      </div>

      {shippingType === SHIPPING_TYPES.DELIVERY && (
        <>
          <InlineSelect
            title="Metode Pengiriman"
            value={checkout?.shipping_method_id}
            options={shippingMethods.map((method) => (
              <option key={method.id} value={method.id}>
                {method.name}
              </option>
            ))}
            onChange={({ target: { value } }) => onChangeShippingMethod(value)}
          />

          {checkout.additional_shipping_fee > 0 && (
            <div className="relative w-full -mt-4 mb-5">
              <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                <div></div>
                <div className="col-span-2 xl:col-span-3 text-xs italic">
                  {`Metode Pengiriman ini dikenakan biaya tambahan Rp ${Utils.numberFormat(
                    checkout.additional_shipping_fee,
                    0,
                  )}`}
                </div>
              </div>
            </div>
          )}

          {isFetchingShippingFee && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}
          {!isFetchingShippingFee && shippingFees.length > 0 && (
            <div className="mb-5 w-full relative">
              <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4">
                <label className="block uppercase text-gray-700 text-xs font-bold">
                  Tipe Pengiriman
                </label>
                <div className="col-span-2 xl:col-span-3">
                  <div className="w-full relative flex flex-wrap items-stretch">
                    {shippingFees.map((shippingFee) => (
                      <div key={shippingFee.code}>
                        {shippingFee.costs
                          .filter((cost) => cost.cost.length > 0)
                          .map((cost) => (
                            <div
                              key={cost.service}
                              className={`mb-2 p-4 pr-10 flex items-center rounded-lg cursor-pointer border-2
                                ${
                                  cost.service === checkout.shipping_method_detail
                                    ? 'border-purple-800'
                                    : ''
                                }`}
                              onClick={() => onChangeShippingMethodType(cost)}>
                              <input
                                type="radio"
                                checked={cost.service === checkout.shipping_method_detail}
                                onChange={() => {}}
                              />
                              <div className="flex-1 ml-5">
                                <h4 className="font-bold">{`${cost.service} (est. ${cost.cost[0].etd} hari)`}</h4>
                                <div className="-mt-1">{`Rp ${Utils.numberFormat(
                                  cost.cost[0].value,
                                  0,
                                )}`}</div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {shippingType === SHIPPING_TYPES.PICKUP && (
        <InlineSelect
          title="Pickup Store"
          options={pickupWarehouseOptions.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </option>
          ))}
          value={selectedWarehouse?.id}
          onChange={({ target: { value } }) => onChangeWarehouse(value)}
        />
      )}
    </Collapsible>
  );
}
